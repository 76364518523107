<template>
  <v-app class="pt-15">

    <!--<customize-header homeRouter="/arPunch/Home"
                      homeTitle="AR打卡"
                      :links="links"></customize-header>-->
    <router-view></router-view>
    <!--<customize-suspendBtn></customize-suspendBtn>-->
    <!--<customize-footer></customize-footer>-->
    
  </v-app>
</template>

<script>
export default {
  name: 'arPunch',
  data: () => ({
    //links: [
    //  {
    //    title: "首页",
    //    router: "/arPunch/Home",
    //    children: []
    //  },
    //  {
    //    title: "解决方案",
    //    router: "/arPunch/solution",
    //    children: []
    //  },
    //],
    
  }),
};
</script>
<style lang="scss" scoped>

</style>